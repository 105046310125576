function ProlookApi(api_host) {
    this.api_host = api_host;
}

ProlookApi.prototype = {
    uploadImage: function(file, callback, errorCallback) {
        var formData = new FormData();
        formData.append('file', file);

        $.ajax({
            data: formData,
            url: this.api_host + "/fileUpload",
            type: "POST",
            processData: false,
            contentType: false,
            crossDomain: true,
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
            success: callback,
            error: errorCallback
        });
    },

    saveDesign: function(customizer_data, callback, errorHandler) {
        var url = this.api_host + "/api/saved_design";
        var params = {
            user: customizer_data.userEmail,
            user_id: customizer_data.userId,
            material_id: customizer_data.materialId,
            material_name: customizer_data.materialName,
            sport: customizer_data.sport,
            brand_id: customizer_data.brand_id,
            brand_version: customizer_data.brand_version,
            builder_customizations: customizer_data.builderCustomizations,
            front_thumbnail: customizer_data.frontImage,
            back_thumbnail: customizer_data.backImage,
            left_thumbnail: customizer_data.leftImage,
            right_thumbnail: customizer_data.rightImage,
            top_thumbnail: customizer_data.topImage,
            name: customizer_data.name,
            notes: customizer_data.notes
        };

        $.ajax({
            url: url,
            dataType: "json",
            type: "POST",
            data: JSON.stringify(params),
            crossDomain: true,
            contentType: 'application/json',
            headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content') },
            success: callback,
            error: errorHandler
        });
    },

    updateDesign: function(customizer_data, callback, errorHandler) {
        var url = this.api_host + "/api/saved_design/update";
        var params = {
            id: customizer_data.savedDesignId,
            user_id: customizer_data.userId,
            material_id: customizer_data.materialId,
            sport: customizer_data.sport,
            builder_customizations: customizer_data.builderCustomizations,
            front_thumbnail: customizer_data.frontImage,
            back_thumbnail: customizer_data.backImage,
            left_thumbnail: customizer_data.leftImage,
            right_thumbnail: customizer_data.rightImage,
            top_thumbnail: customizer_data.topImage,
            name: customizer_data.name,
            notes: customizer_data.notes
        };

        $.ajax({
            url: url,
            dataType: "json",
            type: "POST",
            data: JSON.stringify(params),
            crossDomain: true,
            contentType: 'application/json',
            headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content') },
            success: callback,
            error: errorHandler
        });
    },

    uploadThumbnail: function(image_data_url, callback, errorHandler) {
        var url = location.origin + "/saveLogo";

        $.ajax({
            url: url,
            data: JSON.stringify({ dataUrl: image_data_url }),
            dataType: "json",
            type: "POST",
            crossDomain: true,
            contentType: 'application/json',
            headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')},
            success: callback,
            error: errorHandler
        });
    },

    deleteDesign: function(id, callback, errorHandler) {
        var url = this.api_host + '/api/saved_design/delete';
        var params = {
            id: id
        };

        $.ajax({
            url: url,
            data: JSON.stringify(params),
            dataType: "json",
            type: "POST",
            crossDomain: true,
            contentType: 'application/json',
            headers: {"accessToken": (ub.user !== false) ? atob(ub.user.headerValue) : null},
            success: callback,
            error: errorHandler
        });
    },

    getLastAdded: function(userId, callback, errorHandler) {
        let url = location.origin + `/my-saved-designs/${userId}/getLastAdded`;
        $.get(url, callback).fail(errorHandler);
    },
};
